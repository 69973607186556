import { useEffect, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { usePersonList } from '../Persistence/PersonContext';
import { DataTable } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { useDepartmentList } from '../Persistence/DepartmentContext';
import { MultiSelect } from 'primereact/multiselect';
import Headline from '../Components/Headline';
import { format, parseISO } from 'date-fns';
import { emptyPerson, PersonForm, validatePerson } from './PersonForm';
import { useFormik } from 'formik';
import { copyObject } from '../snippets';
import { EditDialog } from '../Components/EditDialog';
import { deleteData, findData, getData, patchData } from '../feathers';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { useCurrentUser, useCurrentUserIsSuperAdmin } from '../Persistence/CurrentUserContext';
import { paginatorTemplate } from '../Utils/paginatorTemplate';
import { ENUM_ICONS } from '../Components/Icons';
import { ENUM_ROUTES } from '../Navigation/Routes';

const CLASSNAME = 'PersonAdministration'

export const PersonAdministration = () => {
    const [paginationFirst, setPaginationFirst] = useState(0);
    const [paginationRows, setPaginationRows] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const currentUser = useCurrentUser();
    const isSuperadmin = useCurrentUserIsSuperAdmin();
    const dataTableList = usePersonList();
    const dpartmentList = useDepartmentList();
    const [filters, setFilters] = useState({
        'lastname': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'id': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'departmentId': { value: null, matchMode: FilterMatchMode.IN },
        'officePhoneNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'firstname': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [mounted, setMounted] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [displayEditDialog, setDisplayEditDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (dataTableList !== null) {
            setTotalRecords(dataTableList.length);
            setMounted(true)
        }

    }, [mounted, dataTableList])

    const getPerson = async (id) => {
        await getData(ENUM_SERVICES.PERSONS, id).then(async (person) => {
            const rfids = await findData(ENUM_SERVICES.RFIDTAGS, { personId: id })
            person.officePhoneNumber = person.officePhoneNumber || '';
            person.operativeFunctions = person.operativeFunctions.map(e => e.id)
            person.organizationFunctions = person.organizationFunctions.map(e => e.id)
            person.rfids = rfids
            person.oldRfids = copyObject(rfids)
            if (person.G26Date) {
                person.G26Date = parseISO(person.G26Date)
            }
            formik.setValues(person)
        })
    }

    const patchPerson = async () => {
        await patchData(ENUM_SERVICES.PERSONS, formik.values).then(async (person) => {
            //const rfids = await findData(ENUM_SERVICES.RFIDTAGS, { personId: person.id })
            //TODO rfids honzufügen / löschen
            for (let rfid of formik.values.rfids) {
                if (!rfid.id) {
                    // neuer RFID-Tag
                    await patchData(ENUM_SERVICES.RFIDTAGS, { ...rfid, updatedBy: currentUser.id })
                }
            }
            for (let rfid of formik.values.oldRfids) {
                if (!formik.values.rfids.find(e => e.id === rfid.id)) {
                    // RFID-Tag löschen
                    await deleteData(ENUM_SERVICES.RFIDTAGS, rfid.id)
                }
            }
        })
    }

    const onDoubleClick = async (e) => {
        await getPerson(e.data.id)
        setDisplayEditDialog(true);
    }

    const buttons = [
        { key: 'PersonAdministrationButton_1', label: 'Hauptwache', command: () => navigate('/') },
        { key: 'UserAdministrationButton_1', label: 'Systemnutzer', command: () => navigate('/' + ENUM_ROUTES.USERADMINISTRATION), hidden: !isSuperadmin },
    ]

    /** Person formik */
    const formik = useFormik({
        initialValues: copyObject(emptyPerson),
        validate: validatePerson,
        enableReinitialze: true,
        validateOnMount: true,
        validateOnChange: true,
    });

    const onValueChanged = (data) => {
        setTotalRecords(data ? data.length : 0)
    }

    const formatG26Date = (rowdata) => {
        if (rowdata.G26Date) {
            return format(parseISO(rowdata.G26Date), 'yyyy-MM-dd')
        } else {
            return ''
        }
    }

    const renderFooter = () => {
        return <div>Anzahl der angezeigten Personen: {totalRecords}</div>
    }

    const departmentFilter = (options) => {
        return <MultiSelect
            className={' multiselect-custom '}
            id={'multiSelectFilter_department'}
            value={options.value || ''}
            optionValue={'id'}
            optionLabel={"name"}
            options={dpartmentList}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={'Filter Abteilungen'}
            selectedItemsLabel={(options.value ? options.value.length : 0) + ' Abteilungen ausgewählt'}
            maxSelectedLabels={2} />
    }

    const renderIdColumn = (row) => {
        return <div className='flex gap-2 edit-icon'><i className={'pt-1 ' + ENUM_ICONS.PENCIL} onClick={() => onDoubleClick({ data: row })} /><div>{row.id}</div></div>
    }

    return (!mounted ? <></> :
        <div className='flex flex-column w-full'>
            <EditDialog
                header={formik.values.id ? `Person ${formik.values.lastname}, ${formik.values.firstname} anpassen` : 'Neue Person anlegen'}
                displayDialog={displayEditDialog}
                setDisplayDialog={setDisplayEditDialog}
                form={<PersonForm
                    formik={formik}
                    key='personform'
                />}
                formik={formik}
                message='Hier können Sie die Daten der Person anpassen'
                key='editDialogPerson'
                patchForm={patchPerson}
            />
            <Headline buttons={buttons} />
            <div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 200px)' }}>
                <DataTable
                    value={dataTableList}
                    onValueChange={onValueChanged}
                    emptyMessage={'keine Einträge gefunden'}
                    scrollHeight={'calc(100vh - 200px)'}
                    selectionMode="single"
                    scrollable
                    removableSort
                    sortField="lastname" sortOrder={1}
                    filterDisplay='row'
                    filters={filters}
                    paginator
                    paginatorTemplate={paginatorTemplate}
                    first={paginationFirst}
                    rows={paginationRows}
                    responsiveLayout="scroll"
                    dataKey="id"
                    stripedRows
                    rowHover
                    footer={renderFooter}
                    onRowDoubleClick={onDoubleClick}
                >
                    <Column header='Id' field='id' style={{ flexGrow: 0, flexBasis: '80px' }} sortable showFilterMenu={false} body={renderIdColumn} />
                    <Column header='Name' field='lastname' filter style={{ flexGrow: 1, flexBasis: '200px' }} sortable showFilterMenu={false} />
                    <Column header='Vorname' field='firstname' filter style={{ flexGrow: 1, flexBasis: '200px' }} sortable showFilterMenu={false} />
                    <Column header='G26Date' field='G26Date' sortField='G26Date' style={{ flexGrow: 0, flexBasis: '130px' }} sortable showFilterMenu={false} body={formatG26Date} />
                    <Column
                        field='depatmentId'
                        header='Abteilung'
                        body={(row) => row.department.name}
                        style={{ flexGrow: 0, flexBasis: '300px' }}
                        filter={true}
                        filterField="departmentId"
                        filterElement={departmentFilter}
                        showFilterMenu={false}
                    />
                    <Column header='Telefon gesch.' field='officePhoneNumber' filter style={{ flexGrow: 0, flexBasis: '200px' }} showFilterMenu={false} />
                    <Column header='E-Mail' field='email' filter style={{ flexGrow: 1, flexBasis: '200px' }} showFilterMenu={false} />

                </DataTable>
            </div>
        </div>

    )
}