import { ListBox } from "primereact/listbox";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useDepartmentList } from "../Persistence/DepartmentContext";
import { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { Multiselect } from "../Components/Multiselect";
import { useOrganizationFunctionsSelectionList } from "../Persistence/OrganizationFunctionsContext";
import { useOperativeFunctionsSelectionList } from "../Persistence/OperativeFunctionsContext";
import { InputText } from "primereact/inputtext";
import { findData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { Calendar } from "primereact/calendar";


export const emptyUser = { id: null, version: 1, active: 1, lastname: '', firstname: '', roles: '', }

export const UserForm = ({ formik }) => {
    const departmentList = useDepartmentList();
    const organizationFunctionsList = useOrganizationFunctionsSelectionList();
    const operativeFunctionnsList = useOperativeFunctionsSelectionList();
    const [selectedRfid, setSelectedRfid] = useState(null)
    const [deleteRfid, setDeleteRfid] = useState(null)
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const [displayRFIDTagExistsDialog, setDisplayRFIDTagExistsDialog] = useState(false);
    const [newRFIDtag, setNewRFIDTag] = useState('');

    const checkNewRFIDTag = async () => {
        return await findData(ENUM_SERVICES.RFIDTAGS, { rfid: newRFIDtag }).then((result) => {
            return result && result.length > 0
        })
    }

    const removeRFIDTag = () => {
        if (deleteRfid) {
            const newRFidList = formik.values.rfids.filter(e => e.rfid !== deleteRfid.rfid)
            formik.handleChange({ target: { id: 'rfids', value: newRFidList } });
            setSelectedRfid(null)
        }
    }

    const selectedTemplateOperative = (option) => {
        const entry = option ? operativeFunctionnsList.find(e => e.id === option) : null;
        return entry ? <div className="w-full text-xm">{entry.name}</div> : '';
    }

    const selectedTemplateOrganization = (option) => {
        const entry = option ? organizationFunctionsList.find(e => e.id === option) : null;
        return entry ? <div className="w-full text-xm">{entry.name}</div> : '';
    }

    const itemTemplateRfid = useCallback((option) => {
        return <div className='flex'>
            <div className='flex flex-grow-1 align-items-center p-2'>{option.rfid}</div>
            {(!selectedRfid || option.rfid !== selectedRfid.rfid) ? '' :
                <i className='flex pi pi-trash align-items-center p-2' onClick={() => { setDeleteRfid(selectedRfid); setDisplayConfirmDialog(true) }} />}
        </div>
    }, [selectedRfid])


    return <div className='flex person-form'>
        <ConfirmDialog
            handleOnClick={removeRFIDTag}
            handleOnReject={() => setDisplayConfirmDialog(false)}
            labelCancel='Abbrechen'
            labelOk='Löschen'
            message={`Wollen Sie den RFID-Tag ${deleteRfid} wirklich löschen?`}
            title='Löschabfrage RFID-Tag'
            displayConfirmDialog={displayConfirmDialog}
            setDisplayConfirmDialog={setDisplayConfirmDialog}
        />
        <ConfirmDialog
            handleOnClick={e => { }}
            handleOnReject={() => setDisplayRFIDTagExistsDialog(false)}
            labelOk='OK'
            hideCancelButton={true}
            message={`RF-ID Tag mit der Nummer ${newRFIDtag} ist bereits im System vorhanden!`}
            title='RFID-Tag vorhanden'
            displayConfirmDialog={displayRFIDTagExistsDialog}
            setDisplayConfirmDialog={setDisplayRFIDTagExistsDialog}
        />
        <div className='flex flex-column col-6 align-content-start'>
            <InputChangesChecked id='firstname' className='mb-0' autoFocus={true} type="text" formik={formik} label='Vorname' setShowDialog={() => { }} />
            <InputChangesChecked id='lastname' tabIndex={0} type="text" formik={formik} label='Nachname' setShowDialog={() => { }} />
            <InputChangesChecked id='roles' tabIndex={0} type="text" formik={formik} label='Rollen' setShowDialog={() => { }} />


        </div>


    </div>
}

export const validateUser = (data) => {
    let errors = {};

    return errors;
}