import React, { useContext, useState, useEffect } from "react";
import client from '../feathers'
import { logContext, LoggerContext } from "../logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useCurrentUser, useCurrentUserHasRole } from "./CurrentUserContext";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";

const MainMonitorContext = React.createContext();
const listenernames = ['created', 'patched'];
const providername = 'MainMonitorProvider';

export function useMainMonitorList() {
    return useContext(MainMonitorContext);
}
export function MainMonitorProvider({ children }) {
    const [list, setList] = useState([]);
    const [mounted, setMounted] = useState(false)
    const currentUser = useCurrentUser();
    const hasRole = useCurrentUserHasRole();

    const getMainMonitorList = async (data) => {
        if (data) {
            const filteredList = hasRole(ENUM_ROLES.HAUPTWACHE) ? data : data.filter(e => currentUser.departments.find(d => d.id === e.id))
            setList(filteredList);
            logContext(providername, 'info', `########### ${providername} getMainMonitorList update on server data:${data?.departmentList?.length}`)
        }
    }

    const removeListener = () => {
        const serviceRegData = client.service(ENUM_SERVICES.MAINMMONITOR);
        listenernames.forEach((listener) => {
            serviceRegData.removeListener(listener);
        })
    }

    useEffect(() => {
        setMounted(true)
        const serviceRegData = client.service(ENUM_SERVICES.MAINMMONITOR);
        listenernames.forEach((listener) => {
            serviceRegData.removeListener(listener);
            serviceRegData.on(listener, getMainMonitorList);
        })
    }, [])

    useEffect(() => {
        if (mounted) {
            client.service('utils').create({ method: 'main-monitor' }).then((data) => {
                const filteredList = hasRole(ENUM_ROLES.HAUPTWACHE) ? data?.departmentList : data?.departmentList.filter(e => currentUser.departments.find(d => d.id === e.id))
                setList(filteredList);
                logContext(providername, 'info', `########### ${providername} getMainMonitorList init data:${data?.departmentList?.length}`)
            })
            return () => { LoggerContext.info(`########### ${providername} ---- unload`); removeListener(); }
        }
    }, [mounted])

    return (
        <MainMonitorContext.Provider value={list}>
            {children}
        </MainMonitorContext.Provider>
    )
}