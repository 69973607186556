import { useEffect, useRef, useState } from 'react';
import { locale, addLocale } from 'primereact/api';
import { Login } from './Login/login';
import { ChangePassword } from './Login/ChangePassword';
import { Message } from 'primereact/message';
import { useCurrentUser } from './Persistence/CurrentUserContext';
import { logDefault } from './logger';
import { useSocketError } from './Persistence/SocketErrorContext';
import { NavigationPage } from './Navigation/NavigationPage';
import { PersonProvider } from './Persistence/PersonContext';
import { DepartmentProvider } from './Persistence/DepartmentContext';
import { MainMonitorProvider } from './Persistence/MainMonitorContext';
import { OrganizationFunctionsProvider } from './Persistence/OrganizationFunctionsContext';
import { OperativeFunctionsProvider } from './Persistence/OperativeFunctionsContext';
import { UserProvider } from './Persistence/UserContext';

const CLASSNAME = 'Application';
export const Application = () => {
	const [rfidTag, setRfidTag] = useState('');
	const refMainWindow = useRef();
	const currentUser = useCurrentUser();
	const socketError = useSocketError();


	useEffect(() => {
		document.title = 'FFW-GD Einsatz';
		window.onunhandledrejection = event => {
			console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
		};
		addLocale('de', {
			firstDayOfWeek: 1,
			dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
			dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
			monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
			monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			today: 'Tag',
			clear: 'Löschen'
		});
		locale('de')

	}, [])

	const renderPage = () => {
		logDefault(CLASSNAME, 'info', '------------ APPLICATION ------------- ' + (currentUser ? currentUser.username : ''))
		if (socketError && socketError !== null) {
			return <div className="flex p-jc-center p-ai-center" style={{ height: '100%', backgroundColor: 'rgba(255,255,255,0.3)' }}>
				<div className='p-shadow-24 p-as-center ' style={{ width: '400px' }}>
					<Message className='p-6' severity="warn" text={socketError} />
				</div>
			</div>
		} else if (currentUser && currentUser.forceChangePassword === 1) {
			return (
				<ChangePassword user={currentUser} currentUser={currentUser} hrMode={false} hideLogo={false} />
			)
		} else if (currentUser) {
			return <DepartmentProvider>
				<UserProvider>
					<PersonProvider>
						<MainMonitorProvider>
							<OrganizationFunctionsProvider>
								<OperativeFunctionsProvider>
									<NavigationPage />
								</OperativeFunctionsProvider>
							</OrganizationFunctionsProvider>
						</MainMonitorProvider>
					</PersonProvider>
				</UserProvider>
			</DepartmentProvider>
		}
		return <Login />;
	}

	return renderPage()
}
