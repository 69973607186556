import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

export enum ENUM_ROUTES {
	ERROR = 'Fehler'
	, PERSONADMINISTRATION = 'Stammdaten_Personen'
	, USERADMINISTRATION = 'Systemnutzer'
	, SETTINGS = 'Stammdaten'
	, PERSONPROFILE = 'Profil'
	, OPERATIONLISTS = 'Einsatzlisten'
};

export const ROUTES = {
	ERROR: { label: 'Keine Berechtigung', icon: ENUM_ICONS.DEACTIVATE, route: ENUM_ROUTES.ERROR },
	USERADMINISTRATION: { label: 'Nutzer*innen', awesomeIcon: ENUM_AWESOMEICON.USERADMINISTRATION, icon: ENUM_ICONS.USERADMINISTRATION, route: ENUM_ROUTES.PERSONADMINISTRATION },
	SETTINGS: { label: 'Stammdaten', awesomeIcon: ENUM_AWESOMEICON.SETTINGS, icon: ENUM_ICONS.SETTINGS, route: ENUM_ROUTES.SETTINGS },
	USERPROFILE: { label: 'Mein Profil', icon: ENUM_ICONS.USER, route: ENUM_ROUTES.PERSONPROFILE },
}