import React, { useContext, useState, useEffect } from "react";
import client, { getData } from '../feathers'
import { LoggerContext } from "../logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

const UserContext = React.createContext();
const UserSelectionContext = React.createContext();

const servicename = ENUM_SERVICES.USERS;
const listenernames = ['created', 'patched'];
const providername = 'UserProvider';

export function useUserList() {
    return useContext(UserContext);
}
export function useUserSelectionList() {
    return useContext(UserSelectionContext);
}

export function UserProvider({ children }) {
    const [list, setList] = useState(null);
    const [listSelection, setListSelection] = useState([]);
    const [mounted, setMounted] = useState(false)

    const getUserList = async () => {
        await getData(servicename).then((list) => {
            setList(list);
            LoggerContext.info(`########### ${providername} useEffect found:${list.length}`);
        }).catch((error) => { LoggerContext.error("Error " + providername, error) });
    }

    const removeListener = () => {
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
        })
    }

    useEffect(() => {
        setMounted(true)
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
            service.on(listener, getUserList);
        })
    }, [])

    useEffect(() => {
        if (mounted) {
            getUserList()
            return () => { LoggerContext.debug(`########### ${providername} ---- unload`); removeListener(); }
        }
    }, [mounted])

    return (
        <UserContext.Provider value={list}>
            <UserSelectionContext.Provider value={listSelection}>
                {children}
            </UserSelectionContext.Provider>
        </UserContext.Provider>
    )
}